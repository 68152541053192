import React from 'react';
import { GetStaticPropsContext } from 'next';

import { IPage, IPageFields } from 'types/generated/contentful';
import { MetaTags } from 'components/Meta';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { PhotoFeed } from 'components/PhotoFeed';

import {
  getPagePropsBySlug,
  getPhotoGalleryByTitle,
} from 'lib/contentful/contentful-client';
import { getMenuEntriesFromContentful } from 'lib/contentful/menuHelper';

interface IndexPageProps {
  page: IPage;
  preview: boolean;
  photos: any;
  menuEntries: any;
}

const IndexPage = ({ page, photos, menuEntries }: IndexPageProps) => {
  const {
    title,
    includeInSitemap = false,
    seoDescription = '',
  } = page.fields as IPageFields;

  return (
    <>
      <MetaTags
        title={title}
        includeInSitemap={includeInSitemap}
        seoDescription={seoDescription}
      />

      <Header menuEntries={menuEntries} />

      <PhotoFeed photos={photos} />

      <Footer />
    </>
  );
};

export const getStaticProps = async ({
  preview = false,
}: GetStaticPropsContext) => {
  const homeSlug = 'index';
  const pageProps = await getPagePropsBySlug(homeSlug, preview);

  const photoGallery = await getPhotoGalleryByTitle('Home Feed');
  const photos = photoGallery.fields.images;

  const menuEntries = await getMenuEntriesFromContentful('Main-Menu');

  return { props: { ...pageProps, photos, menuEntries } };
};

export default IndexPage;
