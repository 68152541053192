/**
 * Calculates the height of an image based on the width and the aspect ratio
 * which is determined by the image itself.
 */
export const calculateImageHeightFromWidth = (photo: any, width: number) => {
  const aspectRatio =
    photo.fields.file.details.image.width /
    photo.fields.file.details.image.height;
  const height = width / aspectRatio;
  return Math.round(height);
};
