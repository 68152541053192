/* eslint-disable @next/next/no-img-element */
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import { calculateImageHeightFromWidth } from 'lib/contentful/imageSizeHelper';

interface PhotoFeedProps {
  photos: any[];
}
export const PhotoFeed = (props: PhotoFeedProps) => {
  const { photos } = props;
  const theme = useTheme();
  const columns = useMediaQuery(theme.breakpoints.up('md')) ? 3 : 1;

  if (photos.length === 0) {
    return (
      <section className="photo-feed">
        <div>No Photos in Feed</div>;
      </section>
    );
  }

  return (
    <section className="photo-feed">
      <ImageList variant="masonry" cols={columns} gap={8}>
        {photos.map((photo) => (
          <ImageListItem key={photo.fields.file.url}>
            <img
              loading="lazy"
              src={`${photo.fields.file.url}?fm=webp&w=1024`}
              width="1024"
              height={calculateImageHeightFromWidth(photo, 1024)}
              srcSet={`${photo.fields.file.url}?fm=webp&w=2048 2x`}
              alt={photo.fields.title}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </section>
  );
};
