import React, { useState } from 'react';
import NextLink from 'next/link';
import { AppBar, Toolbar, IconButton, Typography, Link } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { MobileMenu } from './MobileMenu';
import { DesktopMenu } from './DesktopMenu';

export interface IMenuEntry {
  text: string;
  link: string;
  external: boolean;
}
interface HeaderProps {
  menuEntries: IMenuEntry[];
}
export const Header = (props: HeaderProps) => {
  const { menuEntries } = props;

  const [open, setOpen] = useState(false);

  const onToggleDrawer = (open: boolean) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(open);
  };

  return (
    <AppBar position="sticky">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open menu"
          onClick={onToggleDrawer(true)}
          sx={{ mr: 2, display: { xs: 'block', sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <MobileMenu
          onToggleHandler={onToggleDrawer}
          open={open}
          menuEntries={menuEntries}
        />
        <Typography
          variant="body1"
          style={{ textTransform: 'uppercase', fontWeight: 500 }}
        >
          <Link href="/" component={NextLink} sx={{ color: 'text.primary' }}>
            Strange Animals Photography
          </Link>
        </Typography>
        <DesktopMenu menuEntries={menuEntries} />
      </Toolbar>
    </AppBar>
  );
};
