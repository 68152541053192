import React from 'react';
import NextLink from 'next/link';

import {
  Box,
  IconButton,
  Divider,
  ListItemButton,
  ListItemText,
  Drawer,
  Link,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IMenuEntry } from './Header';

interface MobileMenuProps {
  onToggleHandler: any;
  open: boolean;
  menuEntries: IMenuEntry[];
}
export const MobileMenu = (props: MobileMenuProps) => {
  const { onToggleHandler, open, menuEntries } = props;

  return (
    <Drawer
      anchor="top"
      variant="temporary"
      open={open}
      onClose={onToggleHandler(false)}
    >
      <Box
        sx={{
          p: 1,
          height: 1,
          backgroundColor: 'white',
        }}
      >
        <IconButton
          sx={{ mb: 2, float: 'right' }}
          onClick={onToggleHandler(false)}
        >
          <CloseIcon style={{ color: 'black' }} />
        </IconButton>

        <Divider sx={{ mb: 2 }} />

        <Box sx={{ mb: 2 }} onClick={onToggleHandler(false)}>
          {menuEntries.map((entry) => (
            <Link
              key={entry.text}
              href={entry.link}
              component={NextLink}
              target={entry.external ? '_blank' : '_self'}
            >
              <ListItemButton sx={{ textDecoration: 'none' }}>
                <ListItemText primary={entry.text} />
              </ListItemButton>
            </Link>
          ))}
        </Box>
      </Box>
    </Drawer>
  );
};
