import Head from 'next/head';

interface MetaTagsProps {
  includeInSitemap: boolean;
  seoDescription: string;
  title: string;
}
export const MetaTags = (props: MetaTagsProps) => {
  const { includeInSitemap = false, seoDescription, title } = props;
  const titleCaption = `Strange Animals Photography: ${title}`; // must be done outside hmtl

  return (
    <Head>
      <title>{titleCaption}</title>
      <meta name="description" content={seoDescription} />
      <meta name="viewport" content="initial-scale=1, width=device-width" />

      {includeInSitemap ? (
        <meta name="robots" content="index, follow" />
      ) : (
        <meta name="robots" content="noindex, nofollow" />
      )}
    </Head>
  );
};
