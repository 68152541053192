import React, { useState } from 'react';
import NextLink from 'next/link';
import { Button, Link, Menu, MenuItem, Box } from '@mui/material';
import { IMenuEntry } from './Header';
interface DesktopMenuProps {
  menuEntries: IMenuEntry[];
}
export const DesktopMenu = (props: DesktopMenuProps) => {
  const { menuEntries } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ ml: 2, display: { xs: 'none', sm: 'block' } }}>
        <Button
          id="info-button"
          sx={{ my: 2, color: 'white', display: 'block' }}
          aria-controls={open ? 'info-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant="outlined"
        >
          Mehr Infos...
        </Button>
        <Menu
          id="info-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'info-button',
          }}
        >
          {menuEntries.map((entry) => (
            <Link
              key={entry.text}
              href={entry.link}
              component={NextLink}
              target={entry.external ? '_blank' : '_self'}
            >
              <MenuItem onClick={handleClose}>{entry.text}</MenuItem>
            </Link>
          ))}
        </Menu>
      </Box>
    </>
  );
};
