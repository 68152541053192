import React from 'react';
import NextLink from 'next/link';
import { styled } from '@mui/material/styles';
import { Container, Typography, Grid, Link } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';

const FooterWrapper = styled('div')`
  display: none;

  ${(props) => props.theme.breakpoints.up('sm')} {
    display: block;
  }
`;

export const Footer = () => {
  return (
    <FooterWrapper>
      <Container
        sx={{
          backgroundColor: (theme) => theme.palette.grey[900],
          p: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Typography
              variant="body1"
              sx={{ textTransform: 'uppercase', fontWeight: 500 }}
            >
              by Kai Mattern
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Link href="/impressum" component={NextLink}>
              Impressum
            </Link>
          </Grid>

          <Grid item xs={2}>
            <Link href="/datenschutz" component={NextLink}>
              Datenschutz
            </Link>
          </Grid>

          <Grid item xs={1}>
            <Link
              href="https://www.instagram.com/strangeanimalsphoto/"
              component={NextLink}
            >
              <InstagramIcon />
            </Link>
          </Grid>
        </Grid>
      </Container>
    </FooterWrapper>
  );
};
